import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";


import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    NavbarText
} from 'reactstrap';

export default function Serverless() {
    return (
        <div class="container">
            <div class="row clearfix">
                <p>
                    <h2>Adventures in Serverless Computing</h2>
                    <p>
                        I'd never really used Amazon services and wanted to expand my horizons.
                    </p>
                    <p>
                        I found a great course <a href='https://www.udemy.com/course/aws-serverless-a-complete-introduction/'>on Udemy</a>
                        that covered setting up API Gateway, AWS Lambda, Dynamo DB, Cognito and hooking it all up to
                        a Single Page Application (SPA).
                    </p>
                    <p>
                        Which was great; I learned a ton, but wasn't sure I could make it work in a normal dev/prod/CI etc enviornment.  It did do a really brief intro to <a href='https://serverless.com/' target='_blank'>Serverless</a> which was cool, but I wasn't sure where to begin.
                    </p>
                    <p>
                        I stumbled onto a <a href='https://serverless-stack.com/#table-of-contents' target='_blank'>tutorial</a> that was perfect.  It covered all the stuff in the other class, but as Serverless.  It also talked about how to write React apps against them and also how to host them on Netlify and set up continuous integration on front and back ends.
                    </p>
                    <p>
                        I normally wouldn't have time to work through all that, but I'm furloughed at the moment, so I had a blast getting it to work.
                    </p>
                    <p>
                        You can see the end result here:
                        <a href='https://henkelsoft-notes.netlify.app'>https://henkelsoft-notes.netlify.app</a>
                    </p>
                    <p>
                        It uses Cognito to manage the signup process/authentication etc.  You can make a new user or use admin@example.com / Passw0rd!
                    </p>
                    <p>
                        The way Amazon set it up is pretty cool; I can post all the source code, but you wouldn't be able to do "break in" without the secret parts that aren't included in Github.
                    </p>
                    <p>
                        It was my first real experience with continuous integration and was pretty cool.
                    </p>
                    <p>
                        This is the backend repo: <a href='https://github.com/lhenkel/serverless-stack-api' target='_blank'>https://github.com/lhenkel/serverless-stack-api</a>
                    </p>

                </p>
            </div>
        </div >
    );
}