import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";


import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    NavbarText
} from 'reactstrap';

export default function CustLocator() {
    return (
        <div class="container">
            <div class="row clearfix">
                <p>
                    <h2>Customer Locator</h2>
                    <p>
                        <br /><a target='_blank' href='https://neaby-dev.netlify.app'>https://neaby-dev.netlify.app</a> (demo data)
                    </p>
                    <p>
                        I originally wrote an app for work that showed nearby customers.
                        However since it had real customer data, I wanted to do a re-write I
                        could showcase.
                    </p>

                    <p>
                        Since I'm furloughed and have lots of time, this
                        spiraled into a complete rewrite from PHP/Laravel to AWS/Serverless.
                    </p>
                    <p>
                        Basic idea is a pretty light React client requests Geolocation from the
                        browser.  It sends the lat/long to the Serverless API which uses Node Geohash
                        library to find the nearest entries in the Dynamo DB.
                    </p>
                    <p>
                        I made a function where a user could add a new prospect (which the real version
                        sends a coupon and adds to SalesForce).  They can also trigger a coupon to an
                        existing customer.  The call just creates an entry w/ the data in the SQS (Simple Queue Server)
                        that Serverless creates.
                    </p>
                    <p>
                        The authentication is managed by Cognito and the backend API uses
                        API Gateway, Lambda functions and Dynamo DB.
                    </p>
                    <p>
                        The data is just Starbucks locations for fake data (I don't work for them,
                        someone just had a geo-coded list I used).
                    </p>

                </p>
            </div>
        </div >
    );
}