import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";


import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    NavbarText
} from 'reactstrap';

export default function Selenium() {
    return (
        <div class="container">
            <div class="row clearfix">
                <p>
                    <h2>This homepage</h2>
                    <p>
                        This site is obviously not mind-blowing.  What I
                        thought was cool was the <a href='https://www.netlify.com/'>Netlify</a>
                        service hosting it.
                    </p>
                    <p>
                        My site is a (really simple ATM) React app.  Netlify is connected to
                        Github, so any change on the master branch rebuilds it.  They also handle
                        the SSL (via LetsEncrypt) and the setup was trivial.
                    </p>
                    <p>
                        Also, at the level I'm at, it's totally free.  They also have other stuff I'm
                        going to look at later like identity management and forms.  Pretty impressed
                        so far.
                    </p>


                </p>
            </div>
        </div >
    );
}