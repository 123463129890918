import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import './Resume.css';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    NavbarText
} from 'reactstrap';

export default function Resume() {
    return (
        <div class="container">
            <div class="row clearfix">
                <p>This will be less formal (and hopefully more interesting) than a formal resume, since I'll talk more about projects and link to repositories when I can.
                    If you want a standard resume, <a href='/LeeHenkelResume.pdf' target="_blank">I've got that too (PDF)</a>
                </p>
                <p>
                    <h2>WORK SUMMARY</h2>

                    <p>
                        I'm the guy you can give a vague outline to and I'll make it happen.  I'm just deep enough on a wide range of systems that I can fill in what I don't know and make it work.  I've also done this long enough that I know what questions to ask and can anticipate some of the minefields.
                </p>
                    <p>
                        I've gravitated to integrations (make this E-commerce system talk to our CRM), but I'm happy working in front-end and back-end systems.
                        I'm also familiar with Cloud systems like Salesforce and Amazon (
                            <a target="_blank" href='https://www.youracclaim.com/badges/5a596d9f-a078-458d-a84f-cf5670f54ec8/public_url'>AWS Developer Associate</a>)

                </p>
                    <p>
                        I've integrated with/written Web Services in Soap/REST
                </p>
                </p>
                <p>
                    <h2 >QUALIFICATIONS </h2>
                    <p >I have experience taking projects from design to implementation
                to maintenance stages</p>

                    <p>Broad range of Web and ERP expertise including back-end,
                front-end and reporting</p>


                </p>

                <p>
                    <h2>TECHNICAL SKILLS</h2>

                    <h3>Programming</h3>
                    <p>I'm stongest programming in PHP, buy I've done projects in several
                    other languages.  Lately I've been diving into React/JS (I've done real projects in it, but am not yet a subject expert).</p>

                    <p>Here other the other languages I'd feel comfortable working in: <br />
                    Apex (SalesForce), ABAP (SAP), Perl, ColdFusion, CSS, HTML 5, XML, Batch Scripting, Java (taken classes, but basically a beginner)

                <h3>Operating Systems</h3>
                        <p>I admin/monitor about a dozen Windows, Linux and Docker systems.</p>

                Applications:  Webmethods, Magento, Crystal Reports, Microsoft SQL Server, Apache, NginX, Vagrant, IIS, Excel, Access, Photoshop
                Cloud: Salesforce Admin/Developer; basic familiarity w/ Amazon S3, API Gateway, Cognito, Dynamo DB.  Very basic familiarity with Kubernetes (I took a class and set up a system from scratch)
</p>

                </p>
                <p>
                    <h2>Job Experience</h2>

                    <p>KNG Inc, Nampa, Idaho									             2006-Current
                    <br /><b>Software Developer/Integration Engineer</b></p>

                    <ul>
                        <li>
                            Lead Developer on customization engine at www.kng.com.  My work included prototyping and coding image compositing.  I also wrote a custom backend system system that enabled proof approval, printed work instructions and notified SAP of current status.
                        </li>
                        <li>
                            Developed SaleForce/SAP/Magento Customer/Order integration using Webmethods as well as custom admin tool using ReactJS.
                        </li>
                        <li>Ran technical side of SAP implementation away from legacy software.  Tasks included hardware specification, data base transfer of 300,000 customer and prospect records.  Developed SOA connection from SAP to IBM Websphere</li>
                        <li>Wrote EDI interface to vendor (including product translation, file transfer and status monitoring) which almost completely automated  3rd party ordering; later revised to batch orders to save on shipping costs</li>
                        <li>Created warehouse production tracking and reporting system which enabled managers to identify trends and isolate errors</li>
                        <li>Wrote program to take legacy Website orders and convert them to SAP orders which was previously a full time job.</li>
                        <li>Worked with consultants to deploy SAP Business Intelligence.  Trained managers to create reports using Business Explorer.  Enhanced cubes with KNG-specific demographic data.</li>
                        <li>Wrote a browser automation test system using <a href='https://github.com/lhenkel/phpgroup_selenium'>Selenium/PHP</a> (link is for an usergroup demo I did, but you get the idea)</li>
                        <li>Created and maintained over 60 reports including a migration to Crystal Report Server XI.</li>
                        <li>Wrote program to generate PDF based barcode labels for vendors from an Excel spreadsheet.  This job was previously done by a graphic designer; the program saved 10 hours per week.</li>
                        <li>Created Flash-based (later re-implemented with JS) embroidery preview tool which decreased returns by 15%.</li>
                        <li>Implemented server room monitoring and paging system (using open source Nagios and custom scripting)</li>
                    </ul>
                    <hr />
                    <p>US Army, Balad, Iraq (called up from Reserves)						                  2005-2006
<br /><b>Platoon Leader</b>
                        <ul>
                            <li>Lead a 35 soldier platoon in asphalt paving missions.</li>
                            <li>Developed overall plans for four major paving operations including timelines, budgets, material estimates and coordinating with outside units</li>
                            <li>Supervised one of the largest military gravel yards in the world.  Modified yard to go from 70 trucks a day to 400.</li>
                            <li>Acted as Unit Movement Officer responsible for moving 135 vehicles, 21 shipping containers, and 150 personnel from Spokane Washington to Balad, Iraq, then back to the US at end of tour.</li>
                            <li>Lead and participated in convoys through some of the most colorful parts of Iraq</li>
                        </ul>





                    </p>
                    <hr />

                    <p>Idaho Public Television, Boise, Idaho						      	                    2002-2006
<br /><b>Webmaster</b>
                        <p><b><i>Note:</i></b> the design has changed a lot since I've worked there.  You can see the version I did on the <a href='https://web.archive.org/web/20090206091503/http://idahoptv.org/'>Internet Archive</a>.</p>
                        <ul>
                            <li>Responsible for overall design and content of website.  Took a completely static site and made it data-aware and standards compliant (CSS & Section 508).</li>
                            <li>Designed and deployed E-commerce solution</li>
                            <li>Redesigned homepage using modern usability and CSS standards http://idahoptv.org</li>
                            <li>Created search engine using Verity and ColdFusion and SQL queries/stored procedures.</li>
                            <li>Wrote program that automated schedule page creation, resulting in saving 8 workdays per month.</li>
                            <li>Designed and implemented custom CMS using ColdFusion and SQL Server 2000.</li>
                        </ul>





                    </p>
                </p>
                <p>
                    <h2>Education</h2>
                    <p>
                        Lafayette College (BA International Affairs)
                    </p>
                    <h2>Online Courses</h2>
                    <p>
                        Coursera: Machine Learning, Intro to Marketing, Intro to Financial Accounting, Cloud Services For
                        Android, Computational Investing, Calculus One, Linear Algebra, Intro To Data Science, Model Thinking Udemy
                        Python for Data Science, Kubernetes Bootcamp, Amazon Serverless Intro
                    </p>
                </p>
            </div>
        </div >
    );
}