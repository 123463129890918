import React, { useState } from 'react';
import { Link, useHistory, NavLink } from "react-router-dom";
import './App.css';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,

  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  NavbarText
} from 'reactstrap';
import { LinkContainer } from "react-router-bootstrap";
import Routes from './Routes';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <div>
        <Navbar color="dark" className=" " dark expand="sm">
          <NavbarBrand className="align-middle" href="/"><img className="rounded-circle logo_img" src='/img/Profile2.png' alt="logo" /></NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse className=" " isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar >
              <NavItem>
                <NavLink className="nav-link" exact={true} to="/">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link" to="/resume">Resume</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link" to="/projects">Projects
                </NavLink>
              </NavItem>
            </Nav>

          </Collapse>
          <NavItem>
            <a className="btn btn-social-icon btn-linkedin mr-3" target='_blank' href="https://www.linkedin.com/in/lee-henkel-6440423/"><i className="fa fa-linkedin"></i></a>
            <a className="btn btn-social-icon btn-github mr-4" target='_blank' href="https://github.com/lhenkel"><i className="fa fa-github"></i></a>
          </NavItem>
        </Navbar>
      </div>

      <main role="main">

        <Routes />

      </main>
    </div >
  );
}
//<Routes />
export default App;
