import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import './Projects.css';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    NavbarText
} from 'reactstrap';

export default function Projects() {
    return (
        <div className="container">
            <div className="row mb-2">
                <div className="col-md-6">
                    <div className="card flex-md-row mb-4 box-shadow h-md-250">
                        <div className="card-body d-flex flex-column align-items-start">

                            <h3 className="mb-0">
                                <Link className="text-dark" to={'/projects/serverless'}>Current Project</Link>

                            </h3>
                            <div className="mb-1 text-muted">May 13, 2020</div>
                            <p className="card-text mb-auto">Nearby customer locator using React/Serverless.</p>
                            <Link to={'/projects/serverless'}>Continue reading</Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card flex-md-row mb-4 box-shadow h-md-250">
                        <div className="card-body d-flex flex-column align-items-start">

                            <h3 className="mb-0">
                                <Link className="text-dark" to={'/projects/serverless'}>Learning Serverless</Link>

                            </h3>
                            <div className="mb-1 text-muted">Apr 29, 2020</div>
                            <p className="card-text mb-auto">Test Project With Serverless/AWS Services and React.</p>
                            <Link to={'/projects/serverless'}>Continue reading</Link>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row mb-2">
                <div className="col-md-6">
                    <div className="card flex-md-row mb-4 box-shadow h-md-250">
                        <div className="card-body d-flex flex-column align-items-start">

                            <h3 className="mb-0">
                                <Link className="text-dark" to={'/projects/selenium'}>Selenium Automation</Link>

                            </h3>
                            <div className="mb-1 text-muted">Mar 1, 2020</div>
                            <p className="card-text mb-auto">Found a decent PHP library by Facebook and made some scripts to help with testing.</p>
                            <Link to={'/projects/selenium'}>Continue reading</Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card flex-md-row mb-4 box-shadow h-md-250">
                        <div className="card-body d-flex flex-column align-items-start">

                            <h3 className="mb-0">
                                <Link className="text-dark" to={'/projects/scale'}>Webservice front-end for scale</Link>

                            </h3>
                            <div className="mb-1 text-muted">Feb 3, 2019</div>
                            <p className="card-text mb-auto">Connecting a shipping scale to SAP was going to cost 30k+, so I wrote a web server in Python and put it on a Raspberry Pi.</p>
                            <Link to={'/projects/scale'}>Continue reading</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-md-6">
                    <div className="card flex-md-row mb-4 box-shadow h-md-250">
                        <div className="card-body d-flex flex-column align-items-start">

                            <h3 className="mb-0">

                                <Link className="text-dark" to={'/projects/timer'}>First React Project</Link>
                            </h3>
                            <div className="mb-1 text-muted">Nov 5, 2019</div>
                            <p className="card-text mb-auto">I made a really simple timer PWA.</p>
                            <Link to={'/projects/timer'}>Continue reading</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}