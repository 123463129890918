import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Resume from "./containers/Resume";
import Serverless from "./containers/Serverless";
import Selenium from "./containers/Selenium";
import Timer from "./containers/Timer";
import Scale from "./containers/Scale";
import Projects from "./containers/Projects";
import Thissite from "./containers/Thissite";
import CustLocator from './containers/CustLocator';
export default function Routes() {
    return (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route exact path="/resume">
                <Resume />
            </Route>
            <Route path="/projects/serverless">
                <Serverless />
            </Route>
            <Route path="/projects/selenium">
                <Selenium />
            </Route>
            <Route path="/projects/thissite">
                <Thissite />
            </Route>
            <Route path="/projects/scale">
                <Scale />
            </Route>
            <Route path="/projects/timer">
                <Timer />
            </Route>
            <Route path="/projects/custlocator">
                <CustLocator />
            </Route>

            <Route path="/projects">
                <Projects />
            </Route>
            <Route>
                <NotFound />
            </Route>
        </Switch>
    );
}