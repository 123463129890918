import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    NavbarText
} from 'reactstrap';

export default function Home() {
    return (
        <div>
            <div className="jumbotron pt-1 pb-1">
                <div className="container">
                    <h2 className="display-3">Hello, world!</h2>
                    <p>I'm currently furloughed and figured it was a good time to set this up.</p>
                    <p>Currently it's just a platform for my resume and hopefully more detailed
                          discussion of projects I've worked on.</p>
                    <p>
                        <Link to={'/resume'}>
                            <Button color="primary">Resume &raquo;</Button>
                        </Link>
                    </p>
                </div>
            </div>

            <div className="container">


                <div className="row">
                    <div className="col-md-4">
                        <h2>AWS Certified!</h2>
                        <p>Had some free time and took the plunge. Brain is fried, but learned a
                            lot in <a href='https://www.udemy.com/course/aws-certified-developer-associate-dva-c01/'>the class</a>.</p>
                        <a target="_blank" href='https://www.youracclaim.com/badges/5a596d9f-a078-458d-a84f-cf5670f54ec8/public_url'>
                            <img width='110' src='AWS-Certified_Developer_Associate.png' />
                        </a>
                    </div>
                    <div className="col-md-4">
                        <h2>Current Project</h2>
                        <p>Nearby customer locator using React/Serverless. (Cognito, Lambda, DynamoDB, S3, SNS).</p>
                        <p>
                            <Link to={'/projects/custlocator'}>
                                <Button color="primary">View details &raquo;</Button>
                            </Link>
                        </p>
                    </div>
                    <div className="col-md-4">
                        <h2>Selenium Testing</h2>
                        <p>This eneded up being really useful. I work more in integration, but sometimes you need to create a bunch of
                orders or customers and this was great.</p>

                        <p>

                            <Link to={'/projects/selenium'}>
                                <Button color="primary">View details &raquo;</Button>
                            </Link>
                        </p>

                    </div>
                </div>

                <hr />

            </div>
        </div>
    );
}