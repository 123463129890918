import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";


import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    NavbarText
} from 'reactstrap';

export default function Timer() {
    return (
        <div class="container">
            <div class="row clearfix">
                <p>
                    <h2>First React Project</h2>
                    <p>
                        I followed a bunch of tutorials and this was my first real try at my own.
                    </p>
                    <p>
                        My physical therapist gave me this routine that's basically a minute work, 15 seconds rest, a minute work.  Then a minute rest and repeat again.  So I grabbed a timer component and wired it up.
                    </p>
                    <p>
                        Since it's a little sloppy in retrospect, I'll probably rewrite it w/ hooks and functional components, but it works reasonably well.
                    </p>
                    <p>
                        You can see the end result here:
                        <a href='https://timer.henkelsoft.com'>https://timer.henkelsoft.com</a>
                    </p>

                    <p>
                        This is the repo: <a href='https://github.com/lhenkel/Timer' target='_blank'>https://github.com/lhenkel/Timer</a>
                    </p>

                </p>
            </div>
        </div >
    );
}