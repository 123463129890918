import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";


import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    NavbarText
} from 'reactstrap';

export default function Scale() {
    return (
        <div class="container">
            <div class="row clearfix">
                <p>
                    <h2>Web Frontend for Scale</h2>
                    <p>
                        Our shipping needed a way to automatically populate the package weight
                        when they shipped an order.  The off-the-shelf way was something like 30k.
                        The SAP coder said if there was some web service, she could pop that onto
                        the screen.
                    </p>
                    <p>
                        I found a library in Python that could read the serial interface on the scale
                        and wrote a simple Python server.  The intial version ran on their Windows
                        shipping station, but it kept dying after a couple days and sometimes our
                        Windows updates would mess with the firewall and break it.  So I switched it
                        to a Raspberry Pi that sat next to it and it's worked great for a year so
                        far.
                    </p>
                    <p>
                        This is the repo: <a href='https://github.com/lhenkel/ToledoScalePiServer' target='_blank'>https://github.com/lhenkel/ToledoScalePiServer</a>
                    </p>

                </p>
            </div>
        </div >
    );
}