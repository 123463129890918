import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";


import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    NavbarText
} from 'reactstrap';

export default function Selenium() {
    return (
        <div class="container">
            <div class="row clearfix">
                <p>
                    <h2>Selenium Browser Automation</h2>
                    <p>
                        I wanted to do more end-to-end testing on a Website.
                        I originally figured I'd write this in Python, but found a great
                        library by <a href='https://github.com/php-webdriver/php-webdriver' target='_blank'>Facebook using PHP</a>.
                    </p>
                    <p>
                        I ended up using it more for spinning up test users and orders for
                        integration testing, but it has been a huge timesaver.
                    </p>
                    <p>
                        This is the Git repo from a talk I did about it: <a href='https://github.com/lhenkel/phpgroup_selenium' target='_blank'>https://github.com/lhenkel/serverless-stack-api</a>.
                    </p>
                    <p>
                        My code is basically starter code since I didn't find Facebook's explanation enough to go on (setting up the Java server was confusing)
                    </p>

                </p>
            </div>
        </div >
    );
}